import { createSlice, current } from '@reduxjs/toolkit'
import { getMyBetsPlinko, placedBetPlinkoGame, postLightningBoardDetails } from '../thunk/plinkoGame.thunk'
import { DEFAULT_PAGE_CALLS, DEFAULT_PLINKO_LIGHTNING_MODE_BOARD } from '../../utils/constants'

const initialState = {
  placedBetData: null,
  betLock: false,
  betLoading: false,
  myBetsData: [],
  myBetsLoading: false,
  lightningBoardLoading: false,
  lightningBoardBallMultipliers: DEFAULT_PLINKO_LIGHTNING_MODE_BOARD.betMultipliers,
  lightningBoardPayouts: DEFAULT_PLINKO_LIGHTNING_MODE_BOARD.payouts,
  betsData: [],
  stateLoaded: false,
  betDataQueue: [],
  lastlightningBoardUpdated: null,
  placeBetLoading: false,
  autoBetsPlaced: false
}

const {
  actions: {
    setBetLock,
    setLightningBoardDetails,
    appendMyBetsPlinko,
    appendBets,
    setPlaceBetLoading,
    setAutoBetsPlaced
  },
  reducer
} = createSlice({
  name: 'plinkoGame',
  initialState,
  reducers: {
    setBetLock: (state, action) => {
      return {
        ...state,
        betLock: false
      }
    },
    setAutoBetsPlaced: (state, action) => {
      return {
        ...state,
        autoBetsPlaced: action?.payload
      }
    },
    setLightningBoardDetails: (state, action) => {
      return {
        ...state,
        lightningBoardBallMultipliers: action.payload?.lightningBoardBallMultipliers ?? DEFAULT_PLINKO_LIGHTNING_MODE_BOARD.betMultipliers,
        lightningBoardPayouts: action.payload?.lightningBoardPayouts ?? DEFAULT_PLINKO_LIGHTNING_MODE_BOARD.payouts,
        lastlightningBoardUpdated: action.payload?.lastLightningBoardDetailsUpdated ?? ''
      }
    },
    appendBets: (state, action) => {
      const betDataQueue = state?.betDataQueue?.length ? [action.payload, ...state?.betDataQueue] : [action.payload]
      return {
        ...state,
        betDataQueue: betDataQueue?.sort((bet1, bet2) => bet1?.id > bet2.id ? 1 : -1)
      }
    },
    setPlaceBetLoading: (state, action) => {
      return {
        ...state,
        placeBetLoading: action.payload
      }
    },
    appendMyBetsPlinko: (state) => {
      const { nextServerSeedHash, ...data } = state?.betDataQueue?.[0] || { nextServerSeedHash: '', data: {} }
      const betData = current(state?.betDataQueue)?.filter((_, index) => index !== 0)
      if (nextServerSeedHash) {
        const myBetsDataRows = [data, ...state.myBetsData?.rows]
        const betsDataRows = [data, ...state.betsData?.rows]
        if (myBetsDataRows.length > DEFAULT_PAGE_CALLS) {
          myBetsDataRows.pop()
          betsDataRows.pop()
        }
        return {
          ...state,
          myBetsData: {
            count: state.myBetsData?.count + 1,
            rows: myBetsDataRows
          },
          betsData: {
            count: state.betsData?.count + 1,
            rows: betsDataRows
          },
          betDataQueue: betData
        }
      }
      return state
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(placedBetPlinkoGame.pending, (state, action) => {
        return {
          ...state,
          betLock: true,
          betLoading: true,
          placeBetLoading: true,
          autoBetsPlaced: true
        }
      })
      .addCase(placedBetPlinkoGame.fulfilled, (state, action) => {
        return {
          ...state,
          placedBetData: action.payload,
          betLoading: false,
          placeBetLoading: false
        }
      })
      .addCase(placedBetPlinkoGame.rejected, (state, action) => {
        return {
          ...state,
          betLock: false,
          betLoading: false,
          placeBetLoading: false,
          autoBetsPlaced: false
        }
      })
      .addCase(getMyBetsPlinko.pending, (state, action) => {
        return {
          ...state,
          myBetsData: action.payload,
          myBetsLoading: true
        }
      })
      .addCase(getMyBetsPlinko.fulfilled, (state, action) => {
        const betsData = state.stateLoaded ? state.betsData : action.payload?.data?.data
        const stateLoaded = true
        return {
          ...state,
          myBetsData: action.payload?.data?.data,
          myBetsLoading: false,
          stateLoaded,
          betsData
        }
      })
      .addCase(postLightningBoardDetails.pending, (state, action) => {
        return {
          ...state,
          lightningBoardLoading: true

        }
      })
      .addCase(postLightningBoardDetails.fulfilled, (state, action) => {
        const lightningBoardDetails = action.payload?.lightningBoardDetails
        return {
          ...state,
          lightningBoardBallMultipliers: lightningBoardDetails?.betMultipliers,
          lightningBoardPayouts: lightningBoardDetails?.payouts,
          lastlightningBoardUpdated: lightningBoardDetails?.lastLightningBoardDetailsUpdated,
          lightningBoardLoading: false
        }
      })
      .addCase(postLightningBoardDetails.rejected, (state, action) => {
        return {
          ...state,
          lightningBoardLoading: false
        }
      })
  }
})

export {
  setBetLock,
  setLightningBoardDetails,
  appendMyBetsPlinko,
  appendBets,
  setPlaceBetLoading,
  setAutoBetsPlaced
}

export default reducer
