import { appendMyBetsPlinko, setBetLock } from '../../redux-store/redux/plinkoGame'
import store from '../../redux-store/store'
import { countOnes } from '../../utils/common-functions/index.js'
import { DEFAULT_PLINKO_LIGHTNING_MODE_BOARD } from '../../utils/constants'

import { boxBounceEffect, changeNumberOfLines, clearAllBalls, createBall, lightningModeUI, removeLightningMode, setupPayoutText, startMovingBall } from './gameUI.js'
import { playBGMSound, playLeftDropSound, playOrangeBoxSound, playRedBoxSound, playRightDropSound, playYellowBoxSound } from './soundManager.js'
import { reflectCreditQueueAmount } from '../../redux-store/redux/user'

export const pixiCreateBall = () => createBall()

export const pixiStartMovingBall = (ballPath) => {
  const ballPathArray = ballPath.split('').map(ele => +ele)
  startMovingBall(ballPathArray)
}

export const pixiCancelBall = () => clearAllBalls()

export const pixiChangeNumberOfLines = (lines) => changeNumberOfLines(lines)

export const pixiSetPayouts = (payouts) => setupPayoutText(payouts)

export const boxNumbers = {
  Red: 1,
  Orange: 2
}

export const boxColor = {
  yellow: 0xFFBD00,
  orange: 0xFF7120,
  red: 0xFF2734,
  white: 0xFFFFFF,
  pink: 0xFF629A,
  alpha: 1,
  whiteSmoke: 0xFFFFF1
}

export const fastModeBox = (dropDetails) => {
  // Comment these lines in standalone mode
  const boxNumber = countOnes(dropDetails)
  boxBounceEffect(boxNumber)
  store.dispatch(reflectCreditQueueAmount())
}

export const dispatching = () => {
  // Comment this line in standalone mode
  store.dispatch(setBetLock())
}

export const dispatchRoundEnd = () => {
  store.dispatch(reflectCreditQueueAmount())
  store.dispatch(appendMyBetsPlinko())
}

// const data = [
//   {
//     position: [1, 1],
//     multiplier: "3x"
//   },
//   {
//     position: [4, 5],
//     multiplier: "7x"
//   },
//   {
//     position: [6, 7],
//     multiplier: "12x"
//   }
// ]

export const pixiLightningMode = (
  ballMultipliers = DEFAULT_PLINKO_LIGHTNING_MODE_BOARD.betMultipliers,
  payouts = DEFAULT_PLINKO_LIGHTNING_MODE_BOARD.payouts
) => {
  lightningModeUI(ballMultipliers)
  pixiSetPayouts(payouts)
}

export const pixiNormalMode = (payouts) => {
  removeLightningMode()
  pixiSetPayouts(payouts)
}

// Sounds
export const playBGM = () => {
  // Background Music
  playBGMSound()
}
export const playRightDrop = () => {
  playRightDropSound()
}
export const playLeftDrop = () => {
  playLeftDropSound()
}
export const playYellowBox = () => {
  playYellowBoxSound()
}
export const playOrangeBox = () => {
  playOrangeBoxSound()
}
export const playRedBox = () => {
  playRedBoxSound()
}
