import { getData, postData } from '../../reactQuery/axios/apiCalls'
import { kenoMyBetsUrl, kenoPlaceBetUrl } from '../../reactQuery/axios/urls'

export const placeBetKenoGameService = (data) => {
  return postData({ url: kenoPlaceBetUrl, body: data })
}

export const getMyBetsKenoService = (params) => {
  return getData({ url: kenoMyBetsUrl, params })
}
