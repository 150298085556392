import { createSlice } from '@reduxjs/toolkit'
import { getMyAllBetsKeno, getMyBetsKeno, placedBetKenoGame } from '../thunk/kenoGame.thunk'
import { DEFAULT_PAGE_CALLS } from '../../utils/constants'

const initialState = {
  placedBetData: null,
  betLock: false,
  betLoading: false,
  myBetsData: [],
  myBetsLoading: false,
  myAllBetsData: []
}

const {
  actions: {
    setBetLock,
    appendMyBetsKeno,
    resetPlacedBetData
  },
  reducer
} = createSlice({
  name: 'kenoGame',
  initialState,
  reducers: {
    setBetLock: (state) => {
      return {
        ...state,
        betLock: false
      }
    },
    resetPlacedBetData: (state) => {
      return {
        ...state,
        placedBetData: null
      }
    },
    appendMyBetsKeno: (state) => {
      const { nextServerSeedHash, ...data } = state.placedBetData || { nextServerSeedHash: '', data: {} }
      if (nextServerSeedHash) {
        const myBetsDataRows = [data, ...state.myBetsData?.rows]
        const myAllBetsData = [data, ...state.myAllBetsData?.rows]
        if (myBetsDataRows.length > DEFAULT_PAGE_CALLS) {
          myBetsDataRows.pop()
        }
        if (myAllBetsData.length > 15) {
          myAllBetsData.pop()
        }
        return {
          ...state,
          myBetsData: {
            count: state.myBetsData?.count + 1,
            rows: myBetsDataRows
          },
          myAllBetsData: {
            count: state.myAllBetsData?.count + 1,
            rows: myAllBetsData
          }
        }
      }
      return state
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(placedBetKenoGame.pending, (state) => {
        return {
          ...state,
          betLock: true,
          betLoading: true
        }
      })
      .addCase(placedBetKenoGame.fulfilled, (state, action) => {
        return {
          ...state,
          placedBetData: action.payload,
          betLoading: false
        }
      })
      .addCase(placedBetKenoGame.rejected, (state) => {
        return {
          ...state,
          betLock: false,
          betLoading: false
        }
      })
      .addCase(getMyBetsKeno.pending, (state, action) => {
        return {
          ...state,
          myBetsData: action.payload,
          myBetsLoading: true
        }
      })
      .addCase(getMyBetsKeno.fulfilled, (state, action) => {
        return {
          ...state,
          myBetsData: action.payload?.data?.data,
          myBetsLoading: false
        }
      })
      .addCase(getMyAllBetsKeno.pending, (state, action) => {
        return {
          ...state,
          myAllBetsData: action.payload,
          myBetsLoading: true
        }
      })
      .addCase(getMyAllBetsKeno.fulfilled, (state, action) => {
        return {
          ...state,
          myAllBetsData: action.payload?.data?.data,
          myBetsLoading: false
        }
      })
  }
})

export {
  setBetLock,
  appendMyBetsKeno,
  resetPlacedBetData
}

export default reducer
