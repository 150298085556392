/* Constants */
// disabled for plinko
// export const PAGINATION_LIMIT = 10
export const DEFAULT_BET_AMOUNT = '1.00'
export const DEFAULT_AUTO_CASHOUT = '5.00'
export const MAX_BUTTON_BOX = 3
export const DEFAULT_BUTTON_DATA = {
  betAmount: DEFAULT_BET_AMOUNT,
  targetPayout: DEFAULT_AUTO_CASHOUT,
  rollOverCount: 0,
  autoBetCounter: 0,
  betAmountError: '',
  autoRateError: '',
  placedBetId: null,
  autoCashOutEnable: true,
  disableInput: false,
  betPlaceDisabled: false,
  autoEscapeResult: ''
}

// Pixi Constants Start

export const LANGUAGES = [
  { label: 'English', languageCode: 'en' },
  { label: 'German', languageCode: 'de' },
  { label: 'Spanish', languageCode: 'es' },
  { label: 'French', languageCode: 'fr' },
  { label: 'Portuguese', languageCode: 'pt' },
  { label: 'Russian', languageCode: 'ru' }
]

/* ==========================================================================
  HTTP Method Types
========================================================================== */
export const METHOD_TYPES = {
  get: 'get',
  post: 'post',
  put: 'put',
  delete: 'delete',
  patch: 'patch'
}

/* ==========================================================================
LocalStorage / Cookie data
========================================================================== */
export const TOKEN = 'authToken'
export const LANGUAGE = 'operator_language'
export const OPERATOR_ID = 'operator_id'
export const ROLE = 'operator_role'

/* ==========================================================================
Operator Roles
========================================================================== */
export const OPERATOR_ROLES = {
  operator: 'OPERATOR',
  operatorUser: 'OPERATOR_USER'
}

/* ==========================================================================
Loader types
========================================================================== */
export const LOADER_TYPE = {
  SCALE: 'scale',
  PULSE: 'pulse'
}

/* ==========================================================================
  TOASTER / NOTIFICATION Types
========================================================================== */
export const TOASTER_TYPE = {
  success: 'success',
  error: 'error',
  warning: 'warning',
  info: 'info'
}

/* ==========================================================================
  Loader Types
========================================================================== */
export const LOADER_HANDLER_TYPES = {
  page: 'pageLoader',
  submit: 'submitButtonLoader',
  table: 'tableLoader'
}

/* ==========================================================================
  All the navigation route Paths
========================================================================== */
export const ROUTE_PATHS = {
  // ----single routes------------
  home: '/',
  crashGame: '/crash',
  plinkoGame: '/plinko',
  rouletteGame: '/roulette-game'
}

/* ==========================================================================
  Game Ids
========================================================================== */
export const DEFAULT_GAME_ID = {
  CRASH: 1,
  PLINKO: 2,
  HI_LO: 3,
  KENO: 4
}

/* ==========================================================================
  Extra Constants Variables
========================================================================== */
export const PAGINATION_LIMIT = 3
export const MIN_WITHDRAW_AMOUNT = 0.003
export const WITHDRAW_FEES = 0.000006
export const DEFAULT_PAGE_CALLS = 8
export const DEFAULT_PRECISION_MONEY = 8
export const DEFAULT_AUTO_BET_COUNTER = 10

/* ==========================================================================
  Game Result
========================================================================== */
export const BET_RESULT = {
  WON: 'won',
  LOST: 'lost',
  CANCELLED: 'cancelled'
}

/* ==========================================================================
  Wallet emit types
========================================================================== */
export const WALLET_EMIT_TYPES = {
  CREDIT: 'credit',
  DEBIT: 'debit',
  RESOLVE: 'resolve',
  DEFAULT: 'default'
}

export const DEFAULT_PLINKO_PINS = 8

export const DEFAULT_PLINKO_RISK_LEVEL = 1

export const DEFAULT_PLINKO_AUTO_BETS = 10

export const PLINKO_FIXED_ODDS = {
  8: [[5.6, 2.1, 1.1, 1, 0.5, 1, 1.1, 2.1, 5.6], [13, 3, 1.3, 0.7, 0.4, 0.7, 1.3, 3, 13], [29, 4, 1.5, 0.3, 0.2, 0.3, 1.5, 4, 29]],
  9: [[5.6, 2, 1.6, 1, 0.7, 0.7, 1, 1.6, 2, 5.6], [18, 4, 1.7, 0.9, 0.5, 0.5, 0.9, 1.7, 4, 18], [43, 7, 2, 0.6, 0.2, 0.2, 0.6, 2, 7, 43]],
  10: [[8.9, 3, 1.4, 1.1, 1, 0.5, 1, 1.1, 1.4, 3, 8.9], [22, 5, 2, 1.4, 0.6, 0.4, 0.6, 1.4, 2, 5, 22], [76, 10, 3, 0.9, 0.3, 0.2, 0.3, 0.9, 3, 10, 76]],
  11: [[8.4, 3, 1.9, 1.3, 1, 0.7, 0.7, 1, 1.3, 1.9, 3, 8.4], [24, 6, 3, 1.8, 0.7, 0.5, 0.5, 0.7, 1.8, 3, 6, 24], [120, 14, 5.2, 1.4, 0.4, 0.2, 0.2, 0.4, 1.4, 5.2, 14, 120]],
  12: [[10, 3, 1.6, 1.4, 1.1, 1, 0.5, 1, 1.1, 1.4, 1.6, 3, 10], [33, 11, 4, 2, 1.1, 0.6, 0.3, 0.6, 1.1, 2, 4, 11, 33], [170, 24, 8.1, 2, 0.7, 0.2, 0.2, 0.2, 0.7, 2, 8.1, 24, 170]],
  13: [[8.1, 4, 3, 1.9, 1.2, 0.9, 0.7, 0.7, 0.9, 1.2, 1.9, 3, 4, 8.1], [43, 13, 6, 3, 1.3, 0.7, 0.4, 0.4, 0.7, 1.3, 3, 6, 13, 43], [260, 37, 11, 4, 1, 0.2, 0.2, 0.2, 0.2, 1, 4, 11, 37, 260]],
  14: [[7.1, 4, 1.9, 1.4, 1.3, 1.1, 1, 0.5, 1, 1.1, 1.3, 1.4, 1.9, 4, 7.1], [58, 15, 7, 4, 1.9, 1, 0.5, 0.2, 0.5, 1, 1.9, 4, 7, 15, 58], [420, 56, 18, 5, 1.9, 0.3, 0.2, 0.2, 0.2, 0.3, 1.9, 5, 18, 56, 420]],
  15: [[15, 8, 3, 2, 1.5, 1.1, 1, 0.7, 0.7, 1, 1.1, 1.5, 2, 3, 8, 15], [88, 18, 11, 5, 3, 1.3, 0.5, 0.3, 0.3, 0.5, 1.3, 3, 5, 11, 18, 88], [620, 83, 27, 8, 3, 0.5, 0.2, 0.2, 0.2, 0.2, 0.5, 3, 8, 27, 83, 620]],
  16: [[16, 9, 2, 1.4, 1.4, 1.2, 1.1, 1, 0.5, 1, 1.1, 1.2, 1.4, 1.4, 2, 9, 16], [110, 41, 10, 5, 3, 1.5, 1, 0.5, 0.3, 0.5, 1, 1.5, 3, 5, 10, 41, 110], [1000, 130, 26, 9, 4, 2, 0.2, 0.2, 0.2, 0.2, 0.2, 2, 4, 9, 26, 130, 1000]]
}

export const DEFAULT_PLINKO_LIGHTNING_MODE_BOARD = {
  betMultipliers: [{ position: [7, 8], multiplier: '2x' }, { position: [13, 10], multiplier: '15x' }, { position: [2, 2], multiplier: '40x' }],
  payouts: [1000, 155, 6.28, 1.09, 0.35, 0.16, 0, 0.02, 0, 0.01, 0, 0.07, 0.3, 1.4, 29.3, 157, 1000]
}

export const PLINKO_LIGHTNING_MODE_VARIABLE_ODDS = {
  1: [500, 125, 30, 5, 0.9, 0.3, 0, 0.1, 0, 0.1, 0, 0.3, 0.9, 5, 30, 125, 500],
  2: [1000, 155, 15, 3.59, 1.4, 0.69, 0, 0.07, 0, 0.04, 0, 0.17, 0.25, 0.46, 1.27, 8.43, 1000],
  3: [1000, 155, 6.28, 1.09, 0.35, 0.16, 0, 0.02, 0, 0.01, 0, 0.07, 0.3, 1.4, 29.3, 157, 1000],
  4: [1000, 6.39, 0.96, 0.32, 0.16, 0.09, 0, 0.02, 0, 0.03, 0, 0.61, 2.03, 7.42, 28.7, 152, 1000]
}

// Pixi Constants end

// Hi lo constants start

/* ==========================================================================
  Card Deck
========================================================================== */
export const CARD_DECK = {
  1: [1, 'spade', 'Ace of spade', 'A-1'],
  2: [2, 'spade', '2 of spade', '2-1'],
  3: [3, 'spade', '3 of spade', '3-1'],
  4: [4, 'spade', '4 of spade', '4-1'],
  5: [5, 'spade', '5 of spade', '5-1'],
  6: [6, 'spade', '6 of spade', '6-1'],
  7: [7, 'spade', '7 of spade', '7-1'],
  8: [8, 'spade', '8 of spade', '8-1'],
  9: [9, 'spade', '9 of spade', '9-1'],
  10: [10, 'spade', '10 of spade', '10-1'],
  11: [11, 'spade', 'Jack of spade', 'J-1'],
  12: [12, 'spade', 'Queen of spade', 'Q-1'],
  13: [13, 'spade', 'King of spade', 'K-1'],
  14: [1, 'club', 'Ace of club', 'A-3'],
  15: [2, 'club', '2 of club', '2-3'],
  16: [3, 'club', '3 of club', '3-3'],
  17: [4, 'club', '4 of club', '4-3'],
  18: [5, 'club', '5 of club', '5-3'],
  19: [6, 'club', '6 of club', '6-3'],
  20: [7, 'club', '7 of club', '7-3'],
  21: [8, 'club', '8 of club', '8-3'],
  22: [9, 'club', '9 of club', '9-3'],
  23: [10, 'club', '10 of club', '10-3'],
  24: [11, 'club', 'Jack of club', 'J-3'],
  25: [12, 'club', 'Queen of club', 'Q-3'],
  26: [13, 'club', 'King of club', 'K-3'],
  27: [1, 'heart', 'Ace of heart', 'A-4'],
  28: [2, 'heart', '2 of heart', '2-4'],
  29: [3, 'heart', '3 of heart', '3-4'],
  30: [4, 'heart', '4 of heart', '4-4'],
  31: [5, 'heart', '5 of heart', '5-4'],
  32: [6, 'heart', '6 of heart', '6-4'],
  33: [7, 'heart', '7 of heart', '7-4'],
  34: [8, 'heart', '8 of heart', '8-4'],
  35: [9, 'heart', '9 of heart', '9-4'],
  36: [10, 'heart', '10 of heart', '10-4'],
  37: [11, 'heart', 'Jack of heart', 'J-4'],
  38: [12, 'heart', 'Queen of heart', 'Q-4'],
  39: [13, 'heart', 'King of heart', 'K-4'],
  40: [1, 'diamond', 'Ace of diamond', 'A-2'],
  41: [2, 'diamond', '2 of diamond', '2-2'],
  42: [3, 'diamond', '3 of diamond', '3-2'],
  43: [4, 'diamond', '4 of diamond', '4-2'],
  44: [5, 'diamond', '5 of diamond', '5-2'],
  45: [6, 'diamond', '6 of diamond', '6-2'],
  46: [7, 'diamond', '7 of diamond', '7-2'],
  47: [8, 'diamond', '8 of diamond', '8-2'],
  48: [9, 'diamond', '9 of diamond', '9-2'],
  49: [10, 'diamond', '10 of diamond', '10-2'],
  50: [11, 'diamond', 'Jack of diamond', 'J-2'],
  51: [12, 'diamond', 'Queen of diamond', 'Q-2'],
  52: [13, 'diamond', 'King of diamond', 'K-2']
}

/* ==========================================================================
  HILO Operation Type
========================================================================== */
export const HI_LO_GAME_BET_TYPE = {
  SAME_OR_ABOVE: 1,
  SAME: 2,
  SAME_OR_BELOW: 3,
  ABOVE: 4,
  BELOW: 5
}

// Hilo constants end

// Keno Constants
export const KENO_PAYOUTS = {
  0: { 0: 0 },
  1: { 0: 0, 1: 3.8 },
  2: { 0: 0, 1: 1, 2: 9.5 },
  3: { 0: 0, 1: 1, 2: 2, 3: 18 },
  4: { 0: 0, 1: 0.5, 2: 1.5, 3: 8, 4: 20 },
  5: { 0: 0, 1: 0.5, 2: 1, 3: 3, 4: 16, 5: 50 },
  6: { 0: 0, 1: 0.5, 2: 1, 3: 2, 4: 4, 5: 24, 6: 70 },
  7: { 0: 0, 1: 0.5, 2: 0.5, 3: 1, 4: 5, 5: 16, 6: 50, 7: 250 },
  8: { 0: 0, 1: 0.5, 2: 0.5, 3: 1, 4: 3, 5: 7, 6: 20, 7: 80, 8: 750 },
  9: { 0: 0, 1: 0.5, 2: 0.5, 3: 1, 4: 2, 5: 4, 6: 10, 7: 25, 8: 60, 9: 1250 },
  10: { 0: 0, 1: 0, 2: 0.5, 3: 1, 4: 2, 5: 3, 6: 5, 7: 10, 8: 20, 9: 200, 10: 1600 },
  11: { 0: 0, 1: 0, 2: 0.5, 3: 1, 4: 1, 5: 2.5, 6: 5, 7: 8, 8: 16, 9: 150, 10: 100, 11: 2500 },
  12: { 0: 0, 1: 0, 2: 0, 3: 1, 4: 1, 5: 2, 6: 4, 7: 7, 8: 30, 9: 200, 10: 800, 11: 1500, 12: 4000 },
  13: { 0: 0, 1: 0, 2: 0, 3: 0.5, 4: 1, 5: 2, 6: 4, 7: 6, 8: 10, 9: 60, 10: 150, 11: 500, 12: 3000, 13: 6000 },
  14: { 0: 0, 1: 0, 2: 0, 3: 0.5, 4: 0.5, 5: 2, 6: 3, 7: 5, 8: 8, 9: 40, 10: 120, 11: 400, 12: 1000, 13: 4000, 14: 8000 },
  15: { 0: 0, 1: 0, 2: 0, 3: 0.5, 4: 0.5, 5: 1.5, 6: 2, 7: 4, 8: 7, 9: 25, 10: 80, 11: 250, 12: 500, 13: 1000, 14: 2500, 15: 10000 },
  16: { 0: 0, 1: 0, 2: 0, 3: 0, 4: 0.5, 5: 0.5, 6: 1, 7: 4, 8: 17, 9: 70, 10: 150, 11: 400, 12: 1000, 13: 2000, 14: 5000, 15: 8000, 16: 12000 },
  17: { 0: 0, 1: 0, 2: 0, 3: 0, 4: 0, 5: 0.5, 6: 1.5, 7: 4, 8: 9, 9: 40, 10: 90, 11: 200, 12: 400, 13: 800, 14: 2000, 15: 7000, 16: 10000, 17: 14000 },
  18: { 0: 0, 1: 0, 2: 0, 3: 0, 4: 0, 5: 0.5, 6: 1.5, 7: 4, 8: 7, 9: 10, 10: 30, 11: 60, 12: 200, 13: 400, 14: 700, 15: 2000, 16: 8000, 17: 11000, 18: 16000 },
  19: { 0: 0, 1: 0, 2: 0, 3: 0, 4: 0, 5: 1, 6: 1.5, 7: 2, 8: 2, 9: 9, 10: 30, 11: 60, 12: 90, 13: 250, 14: 300, 15: 600, 16: 2500, 17: 9000, 18: 12000, 19: 18000 },
  20: { 0: 0, 1: 0, 2: 0, 3: 0, 4: 0, 5: 1, 6: 1, 7: 2.5, 8: 2.5, 9: 3, 10: 10, 11: 40, 12: 90, 13: 150, 14: 200, 15: 400, 16: 700, 17: 3000, 18: 10000, 19: 14000, 20: 20000 }
}
