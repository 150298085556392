import { lazy } from 'react'
const CrashGameLanding = lazy(() => import('../containers/GameLandingPage'))
const PlinkoGameLanding = lazy(() => import('../containers/NewPlinkoGame'))
const HiLoGameLanding = lazy(() => import('../containers/NewHiLoGame'))
const KenoGameLanding = lazy(() => import('../containers/NewKenoGame'))

const routesList = [{
  path: '/',
  component: CrashGameLanding
}, {
  path: '/crash',
  component: CrashGameLanding
}, {
  path: '/plinko',
  component: PlinkoGameLanding
}, {
  path: '/hi-lo',
  component: HiLoGameLanding
}, {
  path: '/keno',
  component: KenoGameLanding
}]

export default routesList
