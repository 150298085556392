import { createAsyncThunk } from '@reduxjs/toolkit'
import { getMyBetsKenoService, placeBetKenoGameService } from '../../network/services/kenoGame.service'
import { setBetLock } from '../redux/kenoGame'

export const placedBetKenoGame = createAsyncThunk('keno-game/place-bet',
  async ({ payload, addToast }, thunkApi) => {
    try {
      const { data } = await placeBetKenoGameService(payload)
      thunkApi.dispatch(setBetLock())
      return data?.data
    } catch (error) {
      addToast('Place Bet Failed', { appearance: 'error' })
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const getMyBetsKeno = createAsyncThunk('keno-game/myBets', async (params, thunkApi) => {
  try {
    const res = await getMyBetsKenoService(params)
    return res
  } catch (error) {
    return thunkApi.rejectWithValue(error[0].description)
  }
})

export const getMyAllBetsKeno = createAsyncThunk('keno-game/myAllBets', async (params, thunkApi) => {
  try {
    const res = await getMyBetsKenoService(params)
    return res
  } catch (error) {
    return thunkApi.rejectWithValue(error[0].description)
  }
})
