import { getData, postData } from '../../reactQuery/axios/apiCalls'
import { plinkoLighteningBoardUrl, plinkoMyBetsUrl, plinkoPlaceBetUrl } from '../../reactQuery/axios/urls'

export const placeBetPlinkoGameService = (data) => {
  return postData({ url: plinkoPlaceBetUrl, body: data })
}

export const getMyBetsPlinkoService = (params) => {
  return getData({ url: plinkoMyBetsUrl, params })
}

export const postLightningBoardDetailsService = () => {
  return postData({ url: plinkoLighteningBoardUrl, body: {} })
}
