
import { path } from './settings.js'
import store from '../../redux-store/store.js'
let BGMSource, rightDropSource, leftDropSource, yellowBoxSource, orangeBoxSource, redBoxSource, clickSource

export function loadSounds () {
  const { PIXI } = window
  BGMSource = PIXI.sound.Sound.from(path.audioPath + 'BGM.mp3')
  rightDropSource = PIXI.sound.Sound.from(path.audioPath + 'Right-Drop.wav')
  leftDropSource = PIXI.sound.Sound.from(path.audioPath + 'Left-Drop.wav')
  yellowBoxSource = PIXI.sound.Sound.from(path.audioPath + 'Yellow-Box.wav')
  orangeBoxSource = PIXI.sound.Sound.from(path.audioPath + 'Orange-Box.wav')
  redBoxSource = PIXI.sound.Sound.from(path.audioPath + 'Red-Box.wav')
  clickSource = PIXI.sound.Sound.from(path.audioPath + 'click.mp3')

  console.log('Audio Loaded')
}
const getMusicStatus = () => {
  const state = store.getState()
  const data = state.user?.userGameToggleSettings
  return data?.sound && data?.music
}
export function playBGMSound () {
  if (getMusicStatus()) {
    BGMSource.play()
  } else {
    BGMSource?.isPlaying && BGMSource.stop()
  }
}
export function playRightDropSound () {
  getMusicStatus() && rightDropSource.play()
}
export function playClickSound () {
  getMusicStatus() && clickSource.play()
}
export function playLeftDropSound () {
  getMusicStatus() && leftDropSource.play()
}
export function playYellowBoxSound () {
  getMusicStatus() && yellowBoxSource.play()
}
export function playOrangeBoxSound () {
  getMusicStatus() && orangeBoxSource.play()
}
export function playRedBoxSound () {
  getMusicStatus() && redBoxSource.play()
}

export function VolumeController (v) {
  BGMSource.volume = v
  rightDropSource.volume = v
  leftDropSource.volume = v
  yellowBoxSource.volume = v
  orangeBoxSource.volume = v
  redBoxSource.volume = v
}
