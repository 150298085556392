import { getData, postData } from '../../reactQuery/axios/apiCalls'
import { genarateServerSeedUrl } from '../../reactQuery/axios/urls'

export const getTransactions = (data, endpoint) => {
  return getData({ url: endpoint, params: data })
}

export const generateServerSeedService = () => {
  return postData({ url: genarateServerSeedUrl, body: {} })
}
