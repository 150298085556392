import * as UI from './gameUI.js'
import { app } from './appPixi.js'
// import { settings } from './settings.js'

export function distance (currentPos, targetPos) {
  let distance = Math.pow((targetPos.x - currentPos.x), 2) + Math.pow((targetPos.y - currentPos.y), 2)
  distance = Math.sqrt(distance)
  return distance
}

export function moveTowards (currentPos, targetPos, speed) {
  const xDirection = targetPos.x - currentPos.x
  const yDirection = targetPos.y - currentPos.y
  currentPos.x += (xDirection * speed)
  currentPos.y += (yDirection * speed)
  return currentPos
}

const ballState = (delta) => UI.containers.ballContainer.forEach(ballContainer => ballContainer.ballState(delta))

const boxState = (delta) => UI.gameAssets.boxes.forEach(box => box.boxState(delta))

const ballShine = (delta) => UI.gameAssets.pinShine.forEach(pinShineArray => {
  pinShineArray.forEach(pinShine => pinShine.shineState(delta))
})

const bgAssets = (delta) => {
  Object.keys(UI.gameAssets.background.left).forEach(asset => UI.gameAssets.background.left[asset].spriteState(delta))
  Object.keys(UI.gameAssets.background.right).forEach(asset => UI.gameAssets.background.right[asset].spriteState(delta))
}

// Update
app.ticker.add((delta) => {
  if (!UI.uiCreated) return

  ballState(delta)
  boxState(delta)
  ballShine(delta)
  if (!UI.bgDestroyed) bgAssets(delta)
})
