import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  socketInstance: null,
  crashGameRoundStartedData: {},
  crashGameRoundStoppedData: {},
  crashGameGraphTimerData: {},
  crashGameTimerData: null,
  crashGameRoundRoundBettingOnHoldData: {},
  crashGameRoundPlacedBets: [],
  newMessageByWS: {},
  unseenMentionCount: null
}

const {
  actions: {
    setCrashGameRoundStartedData, setCrashGameRoundStoppedData, setCrashGameRoundWaitingTimerData, setCrashGameRoundGraphTimerData,
    setCrashGameRoundRoundBettingOnHoldData, setCrashGamePlacedBets, setNewMessageByWS, setPlayerUnseenMessage, setSocketInstance
  },
  reducer
} = createSlice({
  name: 'gameSocketData',
  initialState,
  reducers: {
    setCrashGameRoundStartedData: (state, action) => {
      return {
        ...state,
        crashGameRoundStartedData: action.payload
      }
    },
    setCrashGameRoundStoppedData: (state, action) => {
      return {
        ...state,
        crashGameRoundStoppedData: action.payload
      }
    },
    setCrashGameRoundWaitingTimerData: (state, action) => {
      return {
        ...state,
        crashGameTimerData: action.payload
      }
    },
    setCrashGameRoundGraphTimerData: (state, action) => {
      return {
        ...state,
        crashGameGraphTimerData: action.payload
      }
    },
    setCrashGameRoundRoundBettingOnHoldData: (state, action) => {
      return {
        ...state,
        crashGameRoundRoundBettingOnHoldData: action.payload
      }
    },
    setCrashGamePlacedBets: (state, action) => {
      return {
        ...state,
        crashGameRoundPlacedBets: action.payload
      }
    },
    setNewMessageByWS: (state, action) => {
      return {
        ...state,
        newMessageByWS: action.payload
      }
    },
    setPlayerUnseenMessage: (state, action) => {
      return {
        ...state,
        unseenMentionCount: action.payload
      }
    },
    setSocketInstance: (state, action) => {
      return {
        ...state,
        socketInstance: action.payload
      }
    }
  }
})

export default reducer
export {
  setCrashGameRoundStartedData, setCrashGameRoundStoppedData, setCrashGameRoundWaitingTimerData, setCrashGameRoundGraphTimerData,
  setCrashGameRoundRoundBettingOnHoldData, setCrashGamePlacedBets, setNewMessageByWS, setPlayerUnseenMessage, setSocketInstance
}
