import { createAsyncThunk } from '@reduxjs/toolkit'
import { getMyBetsPlinkoService, placeBetPlinkoGameService, postLightningBoardDetailsService } from '../../network/services/plinkoGame.service'
import { fastModeBox, pixiCancelBall, pixiCreateBall, pixiStartMovingBall } from '../../GameLogic/plinko-game/bridge'
import { appendBets, setAutoBetsPlaced, setBetLock, setPlaceBetLoading } from '../redux/plinkoGame'
import store from '../store'

export const placedBetPlinkoGame = createAsyncThunk('plinko-game/place-bet',
  async ({ payload, fastMode, addToast, autoBet }, thunkApi) => {
    store.dispatch(setPlaceBetLoading(true))
    try {
      if (!fastMode) {
        pixiCreateBall()
      }
      const { data } = await placeBetPlinkoGameService(payload)
      store.dispatch(appendBets(data?.data))
      if (fastMode) {
        fastModeBox(data?.data?.dropDetails)
        thunkApi.dispatch(setBetLock())
        thunkApi.dispatch(setAutoBetsPlaced(false))
      } else {
        pixiStartMovingBall(data?.data?.dropDetails)
        autoBet && setTimeout(() => {
          thunkApi.dispatch(setAutoBetsPlaced(false))
        }, 1000)
      }
      return data?.data
    } catch (error) {
      if (!fastMode) {
        pixiCancelBall()
      }
      addToast('Place Bet Failed', { appearance: 'error' })
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const getMyBetsPlinko = createAsyncThunk('plinko-game/myBets', async (params, thunkApi) => {
  try {
    const res = await getMyBetsPlinkoService(params)
    return res
  } catch (error) {
    return thunkApi.rejectWithValue(error[0].description)
  }
})

export const postLightningBoardDetails = createAsyncThunk('plinko-game/post-lightning-board-details', async (params, thunkApi) => {
  try {
    const res = await postLightningBoardDetailsService()
    return res?.data?.data
  } catch (error) {
    return thunkApi.rejectWithValue(error[0]?.description)
  }
})
