import {
  DEFAULT_PLINKO_PINS
} from '../../utils/constants.js'
import {
  app
} from './appPixi.js'
import {
  boxColor,
  boxNumbers,
  dispatching,
  pixiLightningMode,
  playBGM,
  playRightDrop,
  playLeftDrop,
  playYellowBox,
  playRedBox,
  playOrangeBox,
  dispatchRoundEnd
} from './bridge.js'
import {
  distance,
  moveTowards
} from './gameLogic.js'
import {
  // countTotalPins,
  pinsTransform
} from './pinTranforms.js'
import {
  settings,
  isPixiStandAlone
} from './settings.js'
import {
  loadSounds
} from './soundManager.js'

import {
  spriteSettings
} from './utility.js'

const {
  PIXI
} = window

let uiCreated = false
export const testPath = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
export const testPath1 = [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1]
export const testPath2 = [1, 1, 0, 1, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0]
export const testPath3 = [1, 1, 0, 1, 1, 1, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0]

let path = []

let totalBalls = 0
let totalLines = isPixiStandAlone ? 16 : DEFAULT_PLINKO_PINS

let isSetupBoxes = false

const pinsPosArray = pinsTransform()

const containers = {
  rootContainer: new PIXI.Container(),
  lightningContainer: new PIXI.Container(),
  bgContainer: null,
  pinsContainer: null,
  ballContainer: [],
  bubblesContainer: new PIXI.Container()
}

const globalPoint = new PIXI.Point(0, 0)

const gameAssets = {
  background: {
    left: {
      one: new PIXI.Sprite(),
      two: new PIXI.Sprite(),
      three: new PIXI.Sprite(),
      four: new PIXI.Sprite()
    },
    right: {
      one: new PIXI.Sprite(),
      two: new PIXI.Sprite(),
      three: new PIXI.Sprite(),
      four: new PIXI.Sprite()
    }
  },
  pins: [],
  pinShine: [],
  ball: new PIXI.Sprite(),
  boxes: [],
  boxBlack: new PIXI.Sprite(),
  bubble: null,
  entryCircle: new PIXI.Sprite()
}

const gameValues = {

}

// let bounceBool = false

function createUI() {
  setupContainers()
  setupGameAssets()
  gameScaling()
  loadSounds()
  playBGM()
  uiCreated = true
}

function setupContainers() {
  containers.bgContainer = new PIXI.Container()
  containers.rootContainer.addChild(containers.bgContainer)
  containers.pinsContainer = new PIXI.Container()
  containers.rootContainer.addChild(containers.pinsContainer)
  containers.rootContainer.y = 100
}

function setupGameAssets() {
  setupBG()
  setupPin()
  setupBoxes()
  setupEntryCircle()
}

export let bgDestroyed = false
// let bgCreated = false

function setupBG() {
  app.stage.position.set(app.screen.width / 2, app.screen.height / 2)
  app.stage.addChild(containers.rootContainer)
  app.stage.addChild(containers.lightningContainer)

  bgSetup(gameAssets.background.left.one, PIXI.Assets.get('lightning').textures.Plinko01, 0.7, 1.2)
  bgSetup(gameAssets.background.left.four, PIXI.Assets.get('lightning').textures.Plinko04, 0.8, 1)
  bgSetup(gameAssets.background.left.two, PIXI.Assets.get('lightning').textures.Plinko02, 0.9, 1)
  bgSetup(gameAssets.background.left.three, PIXI.Assets.get('lightning').textures.Plinko03, 1, 1)

  bgSetup(gameAssets.background.right.one, PIXI.Assets.get('lightning').textures.Plinko01, 0.7, -1.2)
  bgSetup(gameAssets.background.right.four, PIXI.Assets.get('lightning').textures.Plinko04, 0.8, -1)
  bgSetup(gameAssets.background.right.two, PIXI.Assets.get('lightning').textures.Plinko02, 0.9, -1)
  bgSetup(gameAssets.background.right.three, PIXI.Assets.get('lightning').textures.Plinko03, 1, -1)

  function bgSetup(sprite, texture, initialDistance, direction) {
    sprite = spriteSettings(sprite, texture, containers.lightningContainer)
    sprite.destination = {
      x: sprite.height / 3 * direction * -1,
      y: -sprite.width / 4
    }
    sprite.initialPosition = {
      x: sprite.height * initialDistance * direction * -1,
      y: -sprite.width / 4
    }
    sprite.targetPosition = {
      x: sprite.initialPosition.x,
      y: sprite.initialPosition.y
    }
    sprite.x = sprite.initialPosition.x
    sprite.y = sprite.initialPosition.y

    sprite.scale.set(direction, 1)

    sprite.doNothing = () => {}
    sprite.move = (delta) => {
      if (distance(sprite.position, sprite.targetPosition) > 0.5) {
        sprite.position = moveTowards(sprite.position, sprite.targetPosition, (settings.bgAssets.speed * delta))
      }
    }

    sprite.spriteState = sprite.move
    bgDestroyed = false
    // bgCreated = true
  }
}

function setupEntryCircle() {
  gameAssets.entryCircle = new PIXI.Sprite(PIXI.Assets.get('plinko_ball').textures.hole)
  // gameAssets.entryCircle.lineStyle(0)
  // gameAssets.entryCircle.beginFill(0x808080, 0.3)
  // gameAssets.entryCircle.drawCircle(0, -60, 16)
  // gameAssets.entryCircle.endFill()
  gameAssets.entryCircle.x = -1
  gameAssets.entryCircle.y = -90
  gameAssets.entryCircle.scale.set(1.2)
  gameAssets.entryCircle.anchor.set(0.5)
  containers.pinsContainer.addChild(gameAssets.entryCircle)
}

const pinInitialScale = 1

function setupPin() {
  const initialPins = settings.pinInFirstLine
  const rows = totalLines

  function pinCreation(array, textureAsset, isEscape) {
    for (let row = 0; row < rows; row++) {
      array.push([])

      const pinNumber = initialPins + row

      for (let pin = 0; pin < pinNumber; pin++) {
        let sprite = new PIXI.Sprite()
        array[row].push(sprite)
        sprite = spriteSettings(sprite, textureAsset, containers.pinsContainer)
        sprite.anchor.set(0.5)
        sprite.scale.set(pinInitialScale)

        const text = new PIXI.Text('', {
          ...settings.styleWhite,
          fontSize: 35
        })
        text.anchor.set(0.6, 0.75)
        text.scale.set(0.3)
        sprite.addChild(text)

        sprite.x = pinsPosArray[row][pin].x

        sprite.y = pinsPosArray[row][pin].y

        sprite.hilighted = false
        shineSetup(sprite, isEscape)
      }
    }
  }
  pinCreation(gameAssets.pins, PIXI.Assets.get('plinko_ball').textures.peg, true)
  pinCreation(gameAssets.pinShine, PIXI.Assets.get('plinko_ball').textures.active_peg, false)

  function shineSetup(pinShine, isEscape) {
    if (isEscape) return
    pinShine.count = 0
    pinShine.isOn = false

    pinShine.alpha = 0
    pinShine.doNothing = () => {}

    pinShine.shineState = pinShine.doNothing

    pinShine.startShine = () => {
      pinShine.count = 0
      pinShine.scale.set(pinInitialScale)
      pinShine.isOn = true
      pinShine.shineState = pinShine.shineEffect
    }

    pinShine.shineEffect = (delta) => {
      if (!pinShine.isOn) return
      pinShine.count++

      const mileStoneOne = 0 // 12
      const mileStoneTwo = mileStoneOne + 10 // 20
      const mileStoneThree = mileStoneTwo + 2

      if (pinShine.count < mileStoneOne) {
        pinShine.alpha = 0
      } else if (pinShine.count >= mileStoneOne && pinShine.count < mileStoneTwo) {
        pinShine.alpha = 1
        pinShine.scale.set(pinInitialScale * 0.7) // 1.02
        pinShine.anchor.set(0.55)
      } else if (pinShine.count >= mileStoneTwo && pinShine.count < mileStoneThree) {
        pinShine.shineState = pinShine.doNothing
        pinShine.alpha = 0
        pinShine.scale.set(pinInitialScale * 1) // 1
        pinShine.isOn = false
      } else {
        pinShine.shineState = pinShine.doNothing
        pinShine.alpha = 0
        pinShine.scale.set(pinInitialScale * 1) // 1
        pinShine.isOn = false
      }
    }
  }

  // Keeping the pins in center always
  containers.pinsContainer.position.set(containers.pinsContainer.x, -containers.pinsContainer.height / 2)
}

function setupBoxes() {
  const boxScale = 1

  for (let box = 1; box < pinsPosArray[totalLines].length - 1; box++) {
    let sprite = new PIXI.Sprite()
    gameAssets.boxes.push(sprite)
    sprite = spriteSettings(sprite, PIXI.Assets.get('plinko_ball').textures.box, containers.rootContainer)
    sprite.scale.set(boxScale)
    sprite.alpha = boxColor.alpha
    sprite.x = pinsPosArray[totalLines][box].x
    sprite.y = sprite.startPos = pinsPosArray[totalLines][box].y * 0.5 - sprite.height * 0.4 + sprite.height * 0.7
    sprite.bouncePos = gameAssets.boxes[0].height * 0.4 + sprite.startPos
    sprite.boxColor = box.white
    sprite.doNothing = () => {}
    sprite.boxState = sprite.doNothing
    sprite.boxBounce = (delta) => {
      if (sprite.y > sprite.startPos) {
        sprite.y -= delta * 1.6
      } else {
        sprite.y = sprite.startPos
        sprite.boxState = sprite.doNothing
      }
    }

    const text = new PIXI.Text('0.5', settings.styleWhite)
    text.anchor.set(0.53, 0.5)
    text.y = 2
    sprite.addChild(text)

    if (box <= boxNumbers.Red || box >= (pinsPosArray[totalLines].length - 1 - boxNumbers.Red)) {
      sprite.tint = boxColor.red
      sprite.sound = 'red'
    } else if (box <= (boxNumbers.Red + boxNumbers.Orange) || box >= (pinsPosArray[totalLines].length - 1 - (boxNumbers.Red + boxNumbers.Orange))) {
      sprite.tint = boxColor.orange
      sprite.sound = 'orange'
    } else {
      sprite.sound = 'yellow'
      // sprite.tint = boxColor.yellow
      text.style.fill = [0x000000]
    }

    sprite.boxColor = sprite.tint
    sprite.bubbles = new PIXI.AnimatedSprite(PIXI.Assets.get('plinko').animations.bubbles)
    sprite.addChild(sprite.bubbles)
    sprite.bubbles.anchor.set(0.5, 0)
    sprite.bubbles.angle = 0 + 180
    sprite.bubbles.scale.set(0.3)
    sprite.bubbles.visible = false
    sprite.bubbles.tint = 0xF15792
    sprite.bubbles.animationSpeed = 1
    sprite.bubbles.onLoop = () => {
      sprite.bubbles.stop()
      sprite.bubbles.visible = false
    }
    sprite.playBubble = () => {
      sprite.bubbles.visible = true
      sprite.bubbles.gotoAndPlay(0)
    }
  }
  isSetupBoxes = true
}

function setupPayoutText(payout) {
  for (let i = 0; i < gameAssets.boxes.length; i++) {
    if (payout[i] || payout[i] === 0) gameAssets.boxes[i].children[0].text = payout[i]
    else console.error('Sent payout is not a valid number')
  }
}

function createBall() {
  totalBalls++
  createBallContainer()

  // const ballSprite = new PIXI.Sprite(PIXI.Assets.get('plinko').textures.betCoin)
  // ballSprite.anchor.set(0.5)
  // ballContiner.addChild(ballSprite)

  // const text = new PIXI.Text('', settings.styleWhite)
  // text.anchor.set(0.5)
  // text.scale.set(1.8)
  // ballSprite.addChild(text)

  // ballSprite.scale.set(0.4)
}

function createBallContainer() {
  const newBallContainer = new PIXI.Container()

  containers.ballContainer.push(newBallContainer)

  containers.rootContainer.addChild(newBallContainer)
  newBallContainer.initialPosition = {
    x: 1.5,
    y: -containers.pinsContainer.height / 2 -
      (newBallContainer.height + gameAssets.pins[0][0].height) * 0.5 -
      settings.bounce.initialHeight * 0.75
  }
  newBallContainer.targetPosition = {
    x: newBallContainer.x,
    y: newBallContainer.y
  }

  newBallContainer.alpha = 0
  newBallContainer.scale.set(0)
  newBallContainer.xVelocity = 0
  newBallContainer.yVelocity = 0.5

  newBallContainer.ballNumber = totalBalls
  newBallContainer.prevLine = -1
  newBallContainer.currentLine = 0
  newBallContainer.lineNumber = 0
  newBallContainer.ballBouncing = true
  newBallContainer.ballMoving = false
  newBallContainer.bounceBool = false
  newBallContainer.startBounce = false
  newBallContainer.audioCallOnce = true
  newBallContainer.dy = 10
  newBallContainer.ball_entry = true
  const totalBoxes = gameAssets.boxes.length
  if (totalBoxes % 2 === 0) {
    newBallContainer.dropBox = (totalBoxes / 2) - 1
  } else {
    newBallContainer.dropBox = ((totalBoxes + 1) / 2) - 1
  }
  let pinPositionNumber = 1
  // const oldPinPosition = 0

  newBallContainer.setTargetPos = (pinPosition) => {
    pinPositionNumber = pinPosition[newBallContainer.lineNumber] > 0 ? pinPositionNumber + 1 : pinPositionNumber

    // if (pinPosition) newBallContainer.targetPosition.x = pinsPosArray[newBallContainer.lineNumber][pinPositionNumber].x
    // if (pinPosition) newBallContainer.targetPosition.y = pinsPosArray[newBallContainer.lineNumber][pinPositionNumber].y - containers.pinsContainer.height * 0.5 - gameAssets.ball.height * 20
  }

  newBallContainer.tick = 0
  newBallContainer.count = 0

  newBallContainer.doNothing = () => {}
  newBallContainer.ballEntry = (delta) => {
    if (newBallContainer.alpha >= 1 && newBallContainer.scale.x >= 1) {
      newBallContainer.ballState = newBallContainer.bounce
      newBallContainer.ball_entry = false
    } else {
      newBallContainer.scale.set(newBallContainer.scale.x + delta * 0.03)
      newBallContainer.alpha += 0.05 * delta
      bubbles.visible = true
      bubbles.play()
    }
  }
  newBallContainer.ballState = newBallContainer.ballEntry

  newBallContainer.bounce = (delta) => {
    if (path[newBallContainer.ballNumber - 1]) {
      newBallContainer.ballBouncing = false
      newBallContainer.ballState = newBallContainer.moveBall
    }
    // newBallContainer.tick += delta * settings.bounce.speed
    // newBallContainer.y = newBallContainer.initialPosition.y + newBallContainer.tick * settings.bounce.initialHeight

    // if (newBallContainer.initialPosition.y + settings.bounce.initialHeight * 0.9 < newBallContainer.y) {
    //   // if (newBallContainer.count > 1) {
    //   if (path[newBallContainer.ballNumber - 1]) {
    //     newBallContainer.ballBouncing = false
    //     newBallContainer.ballState = newBallContainer.moveBall
    //   } else {
    //     // newBallContainer.count = 0
    //   }
    //   // } else {
    //   //   newBallContainer.count += 0.3
    //   // }
    // }
  }

  newBallContainer.ballBounceTick = 0
  newBallContainer.ballBounceEffectAvailabe = false

  newBallContainer.ballBounceStart = () => {
    newBallContainer.ballBounceTick = 0
  }

  newBallContainer.ballBounceEffect = (delta) => {
    if (containers.ballContainer.length > 0) {
      newBallContainer.ballBounceTick += delta * 0.05

      newBallContainer.children[0].y = Math.sin(newBallContainer.ballBounceTick) * 30 - 30
      if (Math.sin(newBallContainer.ballBounceTick) > 0.9) {
        //   debugger
      }
    }
  }
  newBallContainer.moveBall = (delta) => {
    const gravity = totalLines / (totalLines > 12 ? 13 : 15)
    // gravity *= 1.2
    // if (distance(newBallContainer.position, newBallContainer.targetPosition) > 0.5) {
    //   newBallContainer.position = moveTowards(newBallContainer.position, newBallContainer.targetPosition, (settings.movingBall.speed * delta))
    //   if (newBallContainer.lineNumber - 1 > 0 && newBallContainer.lineNumber - 2 < totalLines) {
    //     newBallContainer.ballBounceEffect(delta, newBallContainer)
    //   }
    //   newBallContainer.ballMoving = true
    // } else {
    bubbles.stop()
    bubbles.visible = true
    bubbles.play()

    // if (newBallContainer.y < (settings.gaps.y * totalLines)) {\
    const bottom = gameAssets.boxes[0]
    if (newBallContainer.y < (bottom.y - bottom.height / 2)) {
      newBallContainer.yVelocity += gravity * delta

      newBallContainer.y += newBallContainer.yVelocity * delta
      newBallContainer.x += newBallContainer.xVelocity * delta

      newBallContainer.xVelocity = 0
      // if(distance(newBallContainer.position, ))
      for (let r = 0; r < gameAssets.pins.length; r++) {
        for (let p = 0; p < gameAssets.pins[r].length; p++) {
          const dis = distance(newBallContainer.children[0].toGlobal(globalPoint), gameAssets.pins[r][p].children[0].toGlobal(globalPoint))
          const rad = (newBallContainer.children[0].width / 2 + gameAssets.pins[r][p].children[0].width / 2)
          if (dis <= (rad + (25 / totalLines))) {
            // newBallContainer.children[1].playBubble();
            gameAssets.pinShine[r][p].startShine()
            if (gameAssets.pins[r][p].hilighted) {
              // newBallContainer.children[0].texture = PIXI.Assets.get('lightning').textures.lightningBall
              // newBallContainer.children[0].scale.set(0.3)
              newBallContainer.children[0].tint = boxColor.pink
              newBallContainer.children[0].children[0].text = gameAssets.pins[r][p].children[0].text
              newBallContainer.children[0].children[0].scale.set(1)
              newBallContainer.children[0].children[0].y = -5
              newBallContainer.children[0].children[0].x = -2
            }
            // newBallContainer.yVelocity = newBallContainer.yVelocity < 0 ? 3 * delta : -3 * delta
            newBallContainer.lineNumber = r
            // newBallContainer.xVelocity = path[newBallContainer.ballNumber - 1][r] > 0 ? newBallContainer.xVelocity = 3 * delta : newBallContainer.xVelocity = -3 * delta


            if (path[newBallContainer.ballNumber - 1]) {
              newBallContainer.yVelocity = newBallContainer.yVelocity < 0 ? 4 : -4
              newBallContainer.xVelocity = path[newBallContainer.ballNumber - 1][r] > 0 ?
                newBallContainer.xVelocity = r === 0 ? 8 : 5 :
                newBallContainer.xVelocity = r === 0 ? -8 : -5

              if (newBallContainer.prevLine !== newBallContainer.lineNumber) {
                newBallContainer.prevLine = newBallContainer.lineNumber
                // newBallContainer.setTargetPos(path[newBallContainer.ballNumber - 1])
                if (path[newBallContainer.ballNumber - 1][r] > 0) {
                  playRightDrop()
                } else {
                  playLeftDrop()
                }
              }
            } else {
              newBallContainer.visible = false
            }
          }
        }

        newBallContainer.ballMoving = true
      }

      // newBallContainer.lineNumber = 5
      // newBallContainer.lineNumber++

      // if (newBallContainer.lineNumber - 1 < totalLines) {
      //   gameAssets.pinShine[newBallContainer.lineNumber - 1][pinPositionNumber].startShine()

      //   // change ball at lightning mode
      //   if (gameAssets.pins[newBallContainer.lineNumber - 1][pinPositionNumber].hilighted) {
      //     newBallContainer.children[0].texture = PIXI.Assets.get('lightning').textures.lightningBall
      //     newBallContainer.children[0].scale.set(0.3)
      //     newBallContainer.children[0].tint = boxColor.pink
      //     newBallContainer.children[0].children[0].text = gameAssets.pins[newBallContainer.lineNumber - 1][pinPositionNumber].children[0].text
      //   }
      // }
    } else {
      // Ball reached end

      // gameAssets.boxes[pinPositionNumber - 1].y = gameAssets.boxes[pinPositionNumber - 1].bouncePos
      // gameAssets.boxes[pinPositionNumber - 1].playBubble()

      if (path[newBallContainer.ballNumber - 1]) {

        const zeroAr = path[newBallContainer.ballNumber - 1].slice(0, totalLines).filter((val) => !val)
        newBallContainer.zeroCnt = zeroAr.length
        newBallContainer.oneCnt = totalLines - zeroAr.length
        let diff = 0

        if (newBallContainer.zeroCnt >= newBallContainer.oneCnt) {
          diff = newBallContainer.zeroCnt - newBallContainer.oneCnt
          if (diff % 2 === 0) {
            diff = diff / 2
          } else {
            diff = (diff - 1) / 2
          }
          newBallContainer.dropBox -= diff
        } else {
          diff = newBallContainer.oneCnt - newBallContainer.zeroCnt
          if (diff % 2 === 0) {
            diff = diff / 2
          } else {
            diff = (diff - 1) / 2
          }
          if (totalLines % 2 !== 0) diff++
          newBallContainer.dropBox += diff
        }
        gameAssets.boxes[newBallContainer.dropBox].y = gameAssets.boxes[newBallContainer.dropBox].bouncePos
        gameAssets.boxes[newBallContainer.dropBox].playBubble()
        if (gameAssets.boxes[newBallContainer.dropBox].sound === 'red') {
          playRedBox()
        } else if (gameAssets.boxes[newBallContainer.dropBox].sound === 'orange') {
          playOrangeBox()
        } else if (gameAssets.boxes[newBallContainer.dropBox].sound === 'yellow') {
          playYellowBox()
        }
        newBallContainer.ballState = newBallContainer.ballFly
      } else {
        newBallContainer.visible = false
      }
    }

    // if (newBallContainer.lineNumber - 1 < totalLines) {
    //   // gameAssets.pinShine[newBallContainer.lineNumber - 1][pinPositionNumber].startShine()
    // }

    newBallContainer.ballBounceStart()
    // }

    // newBallContainer.ballFly = (delta) => {
    //   newBallContainer.y -= delta
    //   newBallContainer.alpha -= delta * 0.005
    //   ballSprite.alpha = 0

    //   if (newBallContainer.alpha < 0.1) {
    //     newBallContainer.ballState = newBallContainer.doNothing
    //     newBallContainer.ballMoving = false
    //     newBallContainer.destroyBall()
    //   }

    //   // gameAssets.boxes[pinPositionNumber - 1].boxState = gameAssets.boxes[pinPositionNumber - 1].boxBounce
    //   gameAssets.boxes[newBallContainer.dropBox].boxState = gameAssets.boxes[newBallContainer.dropBox].boxBounce
    // }

    newBallContainer.ballFly = (delta) => {
      newBallContainer.y -= delta
      newBallContainer.alpha -= delta * 0.5
      ballSprite.alpha = 0

      if (newBallContainer.alpha < 0.1) {
        newBallContainer.ballMoving = false
        newBallContainer.destroyBall()
        newBallContainer.ballState = newBallContainer.doNothing
      }

      gameAssets.boxes[newBallContainer.dropBox].boxState = gameAssets.boxes[newBallContainer.dropBox].boxBounce
    }

    if (!newBallContainer.startBounce && distance(gameAssets.boxes[newBallContainer.dropBox], newBallContainer.position) <=
      (gameAssets.boxes[newBallContainer.dropBox].height / 2 + newBallContainer.height / 4)) {
      newBallContainer.bounceBool = true
      if (newBallContainer.audioCallOnce) {
        newBallContainer.audioCallOnce = false

        // Credit after ball destroyed
        if (!isPixiStandAlone) {
          dispatchRoundEnd()
        }
      }
    }
  }

  newBallContainer.destroyBall = () => {
    if (newBallContainer.ballMoving) return

    containers.ballContainer = containers.ballContainer.filter(item => item !== newBallContainer)

    newBallContainer.destroy({
      children: true
    })

    if (containers.ballContainer.length === 0) {
      !isPixiStandAlone && dispatching()
    }
  }
  newBallContainer.ballState = newBallContainer.ballEntry

  newBallContainer.position.set(newBallContainer.initialPosition.x, newBallContainer.initialPosition.y)
  newBallContainer.targetPosition.x = newBallContainer.x
  newBallContainer.targetPosition.y = newBallContainer.y

  const ballSprite = new PIXI.Sprite(PIXI.Assets.get('plinko_ball').textures.ball)
  ballSprite.anchor.set(0.5)
  ballSprite.scale.set(0.85)
  newBallContainer.addChild(ballSprite)

  const text = new PIXI.Text('', settings.styleWhite)
  text.anchor.set(0.5)
  text.scale.set(1.8)
  ballSprite.addChild(text)

  // ballSprite.scale.set(0.4)

  const bubbles = new PIXI.AnimatedSprite(PIXI.Assets.get('plinko').animations.bubbles)
  newBallContainer.addChild(bubbles)
  bubbles.anchor.set(0.5, 0.2)
  bubbles.angle = 0 + 180
  bubbles.scale.set(0.3)
  bubbles.visible = false
  bubbles.animationSpeed = 1.4
  bubbles.tint = 0xF15792
  bubbles.onLoop = () => {
    // bubbles.stop()
    bubbles.gotoAndStop(0)
    bubbles.visible = false
  }
  // bubbles.play()

  return newBallContainer
}

function startMovingBall(ballPath) {
  path.push([...ballPath])
}

function changeNumberOfLines(reqLines) {
  isSetupBoxes = false
  totalLines = reqLines

  function destroyBgContianer() {
    containers.rootContainer.destroy({
      children: true
    })
  }

  function createpinsContainer() {
    containers.rootContainer = new PIXI.Container()
    containers.pinsContainer = new PIXI.Container()
    setupContainers()
  }

  function resetValues() {
    containers.ballContainer = []
    gameAssets.pins = []
    gameAssets.pinShine = []
    gameAssets.ball = new PIXI.Sprite()
    gameAssets.boxes = []
  }
  bgDestroyed = true
  destroyBgContianer()
  createpinsContainer()
  resetValues()
  setupGameAssets()
  gameScaling()
}

function clearAllBalls() {
  containers.ballContainer.forEach(ballContainer => {
    ballContainer.destroyBall()
  })

  totalBalls = 0

  path = []
}

function scaleFactor() {
  return (settings.maxLines + 16) / (totalLines + 16)
}

function gameScaling() {
  containers.rootContainer.scale.set(scaleFactor())
}

function boxBounceEffect(index) {
  const box = gameAssets.boxes[index]

  if (!box) return

  box.y = box.bouncePos
  box.boxState = box.boxBounce
  box.playBubble()
}

// let isLightningMode = false

export function normalModeUI() {
  gameAssets.pins.forEach(pin => {
    pin.forEach(p => {
      p.scale.set(pinInitialScale)
      p.tint = boxColor.white
      p.children[0].text = ''
      p.hilighted = false
    })
  })
}

export function lightningModeUI(lightData) {
  // Resetting all pins
  normalModeUI()

  setupScullBoxes(true)

  lightData.forEach(pin => {
    const row = pin.position[0] - 1
    const column = pin.position[1] - 1

    const targetPin = gameAssets.pins[row][column]
    targetPin.scale.set(pinInitialScale * 1.7)
    targetPin.tint = boxColor.pink
    targetPin.children[0].text = pin.multiplier

    targetPin.hilighted = true
  })

  Object.keys(gameAssets.background.left).forEach(asset => {
    gameAssets.background.left[asset].targetPosition.x = gameAssets.background.left[asset].destination.x
    gameAssets.background.left[asset].targetPosition.y = gameAssets.background.left[asset].destination.y
  })
  Object.keys(gameAssets.background.right).forEach(asset => {
    gameAssets.background.right[asset].targetPosition.x = gameAssets.background.right[asset].destination.x
    gameAssets.background.right[asset].targetPosition.y = gameAssets.background.right[asset].destination.y
  })
}

function setupScullBoxes(isScull) {
  if (gameAssets.boxes[6]) setupScull(6)
  if (gameAssets.boxes[8]) setupScull(8)
  if (gameAssets.boxes[10]) setupScull(10)

  function setupScull(boxIndex) {
    // texture
    gameAssets.boxes[boxIndex].texture = isScull ?
      PIXI.Assets.get('lightning').textures.boxBlack :
      PIXI.Assets.get('plinko_ball').textures.box
    // scale
    gameAssets.boxes[boxIndex].scale.x = gameAssets.boxes[boxIndex].scale.y = isScull ? 0.64 : 1
    // tint
    gameAssets.boxes[boxIndex].tint = isScull ? boxColor.white : gameAssets.boxes[boxIndex].boxColor
    // sound
    gameAssets.boxes[boxIndex].sound = isScull ? 'none' : gameAssets.boxes[boxIndex].sound

    // text
    gameAssets.boxes[boxIndex].children[0].visible = !isScull
  }
}

export function removeLightningMode() {
  normalModeUI()
  setupScullBoxes(false)

  // return animation
  Object.keys(gameAssets.background.left).forEach(asset => {
    gameAssets.background.left[asset].targetPosition.x = gameAssets.background.left[asset].initialPosition.x
    gameAssets.background.left[asset].targetPosition.y = gameAssets.background.left[asset].initialPosition.y
  })
  Object.keys(gameAssets.background.right).forEach(asset => {
    gameAssets.background.right[asset].targetPosition.x = gameAssets.background.right[asset].initialPosition.x
    gameAssets.background.right[asset].targetPosition.y = gameAssets.background.right[asset].initialPosition.y
  })
}

export {
  containers,
  gameAssets,
  gameValues,
  createUI,
  uiCreated,
  createBall,
  startMovingBall,
  changeNumberOfLines,
  clearAllBalls,
  setupPayoutText,
  isSetupBoxes,
  boxBounceEffect
}

isPixiStandAlone && divButtons()

function divButtons() {
  const createBallDiv = document.createElement('button')
  createBallDiv.innerHTML = 'createBall'
  createBallDiv.onclick = () => createBall()
  document.body.appendChild(createBallDiv)

  const boxBounceDiv = document.createElement('button')
  boxBounceDiv.innerHTML = 'boxBounce'
  boxBounceDiv.onclick = () => boxBounceEffect(parseInt(prompt('Enter number of boxBounce')))
  document.body.appendChild(boxBounceDiv)

  const linesDiv = document.createElement('button')
  linesDiv.innerHTML = 'lines'
  linesDiv.onclick = () => changeNumberOfLines(parseInt(prompt('Enter number of lines')))
  document.body.appendChild(linesDiv)

  const lightningModeDiv = document.createElement('button')
  lightningModeDiv.innerHTML = 'lightningMode'
  lightningModeDiv.onclick = () => pixiLightningMode()
  document.body.appendChild(lightningModeDiv)

  const normalModeDiv = document.createElement('button')
  normalModeDiv.innerHTML = 'normalMode'
  normalModeDiv.onclick = () => removeLightningMode()
  document.body.appendChild(normalModeDiv)

  const clearAllBallDiv = document.createElement('button')
  clearAllBallDiv.innerHTML = 'clearAllBall'
  clearAllBallDiv.onclick = () => clearAllBalls()
  document.body.appendChild(clearAllBallDiv)

  const ballPathOneDiv = document.createElement('button')
  ballPathOneDiv.innerHTML = 'ballPathOne'
  ballPathOneDiv.onclick = () => startMovingBall(testPath)
  document.body.appendChild(ballPathOneDiv)

  const ballPathTwoDiv = document.createElement('button')
  ballPathTwoDiv.innerHTML = 'ballPathTwo'
  ballPathTwoDiv.onclick = () => startMovingBall(testPath1)
  document.body.appendChild(ballPathTwoDiv)

  const ballPathThreeDiv = document.createElement('button')
  ballPathThreeDiv.innerHTML = 'ballPathThree'
  ballPathThreeDiv.onclick = () => startMovingBall(testPath2)
  document.body.appendChild(ballPathThreeDiv)

  const ballPathFourDiv = document.createElement('button')
  ballPathFourDiv.innerHTML = 'ballPathFour'
  ballPathFourDiv.onclick = () => startMovingBall(testPath3)
  document.body.appendChild(ballPathFourDiv)
}