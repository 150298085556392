import React, { Suspense } from 'react'
import {
  BrowserRouter as Router
} from 'react-router-dom'
import LoaderRound from '../components/LoaderRound'
import CustomRoutes from './routes'
import WebSocketConnectionProvider from '../reactQuery/ws'

const Routes = () => (
  <Router>
    <Suspense fallback={<LoaderRound />}>
      <WebSocketConnectionProvider>
        <CustomRoutes />
      </WebSocketConnectionProvider>
    </Suspense>
  </Router>
)

export default Routes
