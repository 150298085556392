import { createSlice } from '@reduxjs/toolkit'
import { cashOutHiLo, currentBetState, getMyBetsHiLo, openCardHiLo, placeBetHiLo } from '../../redux-store/thunk/hiLoGame.thunk'
import { randomCardGenerate } from '../../utils/common-functions/index'
import { BET_RESULT, DEFAULT_PAGE_CALLS } from '../../utils/constants'

const initialState = {
  initialCard: randomCardGenerate(),
  betStates: null,
  betData: null,
  betLock: false,
  betHistory: null,
  previousSelectedMultiplier: null,
  stateLoaded: false,
  cardLoaded: false,
  betsData: []
}

const {
  actions: {
    setInitialCard,
    setMyInitialCard,
    setStateLoaded,
    setCardLoaded
  },
  reducer
} = createSlice({
  name: 'hiloGame',
  initialState,
  reducers: {
    setInitialCard: (state, action) => {
      return {
        ...state,
        initialCard: action.payload
      }
    },
    setBetLock: (state, action) => {
      return {
        ...state,
        betLock: action.payload
      }
    },
    setMyInitialCard: (state, action) => {
      return {
        ...state,
        myInitialCard: action.payload
      }
    },
    setStateLoaded: (state, action) => {
      return {
        ...state,
        stateLoaded: action.payload
      }
    },
    setCardLoaded: (state, action) => {
      return {
        ...state,
        cardLoaded: action.payload
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(placeBetHiLo.fulfilled, (state, action) => {
        return {
          ...state,
          betLock: true,
          betData: action.payload
        }
      })
      .addCase(openCardHiLo.fulfilled, (state, action) => {
        let betStates = null
        let initialCard = null
        const cardLoaded = true
        let betLock = state.betLock
        let myInitialCard = action?.payload?.initialCard
        if (action.payload?.betStates && action.payload.betStates.length > 0) {
          betStates = (action.payload.betStates).reverse()
          initialCard = betStates?.[0]?.openedCard
        }
        let betHistory = state.betHistory
        let betsData = state.betHistory
        if (action.payload?.result && action.payload.result === BET_RESULT.LOST) {
          betLock = false
          betStates = null
          myInitialCard = initialCard
          betHistory = state.betHistory.rows ? { count: state.betHistory.count + 1, rows: [action.payload, ...state.betHistory.rows] } : { count: 1, rows: [action.payload] }
          betsData = state.betHistory.rows ? { count: state.betHistory.count + 1, rows: [action.payload, ...state.betHistory.rows] } : { count: 1, rows: [action.payload] }
          if (betHistory.rows?.length > DEFAULT_PAGE_CALLS) {
            betHistory?.rows?.pop()
            betsData?.rows?.pop()
          }
        }
        return {
          ...state,
          betLock,
          initialCard,
          betStates,
          myInitialCard,
          betHistory,
          cardLoaded,
          betsData
        }
      })
      .addCase(currentBetState.fulfilled, (state, action) => {
        let betStates = null
        let initialCard = null
        const betLock = true
        const cardLoaded = true
        const betAmount = action?.payload?.betAmount
        const myInitialCard = action?.payload?.initialCard
        if (action.payload?.betStates && action.payload.betStates.length > 0) {
          betStates = (action.payload.betStates).reverse()
          initialCard = betStates?.[0]?.openedCard
        } else if (action.payload?.betStates && action.payload.betStates.length === 0) {
          initialCard = action?.payload?.initialCard
        }
        return {
          ...state,
          betLock,
          initialCard,
          betStates,
          betAmount,
          myInitialCard,
          cardLoaded
        }
      })
      .addCase(cashOutHiLo.fulfilled, (state, action) => {
        const myInitialCard = null
        const betHistory = state.betHistory.rows ? { count: state.betHistory.count + 1, rows: [action.payload, ...state.betHistory.rows] } : { count: 1, rows: [action.payload] }
        const betsData = state.betHistory.rows ? { count: 8, rows: [action.payload, ...state.betHistory.rows] } : { count: 1, rows: [action.payload] }
        if (betHistory?.rows?.length > DEFAULT_PAGE_CALLS) {
          betHistory?.rows?.pop()
          betsData?.rows?.pop()
        }
        return {
          ...state,
          myInitialCard,
          betLock: false,
          betData: action.payload,
          betStates: null,
          betAmount: '',
          betHistory,
          betsData
        }
      })
      .addCase(getMyBetsHiLo.fulfilled, (state, action) => {
        const betsData = state.betsLoaded ? state.betsData : action?.payload
        const betsLoaded = true
        return {
          ...state,
          betHistory: action.payload,
          betsData,
          betsLoaded
        }
      })
  }
})

export {
  setInitialCard,
  setMyInitialCard,
  setStateLoaded,
  setCardLoaded
}

export default reducer
