import { combineReducers } from '@reduxjs/toolkit'
import user from './user'
import gameSetting from './gameSetting'
import betHistory from './betHistory'
import gameSocketData from './gameSocketData'
import plinkoGame from './plinkoGame'
import hiloGame from './hiLoGame'
import kenoGame from './kenoGame'

const rootReducer = combineReducers({
  user,
  gameSetting,
  betHistory,
  gameSocketData,
  plinkoGame,
  hiloGame,
  kenoGame
})

export default rootReducer
