import { app } from './appPixi.js'
import { settings } from './settings.js'
const {
  PIXI
} = window

const appWidth = app.screen.width
const appHeight = app.screen.height

// const aspectRatio = appWidth / appHeight

const aspectRatioX = appWidth / settings.originalWidth
const aspectRatioY = appHeight / settings.originalHeight

function responsiveSettings (sprite) {
  const xResponsive = (x) => (x * aspectRatioX) + (sprite.width / 2)
  const yResponsive = (y) => (y * aspectRatioY) + (sprite.height / 2)
  sprite.xResp = (x) => xResponsive(x)
  sprite.yResp = (y) => yResponsive(y)

  sprite.positionResponsive = (x, y) => sprite.position.set(xResponsive(x), yResponsive(y))

  const ar = (aspectRatioX > aspectRatioY) ? aspectRatioY : aspectRatioX
  sprite.scaleResponsive = (s) => sprite.scale.set(s * ar)
}

export function spriteSettings (sprite, textureName, containerName) {
  sprite.texture = textureName
  containerName.addChild(sprite)
  sprite.anchor.set(0.5)

  responsiveSettings(sprite)

  return sprite
};

export function animatedSpriteSettings (sprite, frames, containerName) {
  sprite = new PIXI.AnimatedSprite(frames)
  sprite.textures = frames
  containerName.addChild(sprite)
  sprite.anchor.set(0.5)
  responsiveSettings(sprite)
  return sprite
};

// export function scaleResp(scale) {
//     let ar = (aspectRatioX > aspectRatioY) ? aspectRatioY : aspectRatioX
//     return scale * ar;
// }

// export function positionResp(xPos, yPos, img) {
//     let pos = {
//         _x: 0,
//         _y: 0
//     }
//     pos._x = ((xPos * aspectRatioX) + (img.width / 2)).toFixed(2);
//     pos._y = ((yPos * aspectRatioY) + (img.height / 2)).toFixed(2);
//     return pos;
// }
