import { settings } from './settings.js'

const gapX = settings.gaps.x
const gapY = settings.gaps.y
const initialPins = settings.pinInFirstLine
const rows = settings.maxLines + 1

export const pinsTransform = () => {
  const pinsPositions = []

  for (let row = 0; row < rows; row++) {
    pinsPositions.push([])

    const pinNumber = initialPins + row

    for (let pin = 0; pin < pinNumber; pin++) {
      const xPos = pinNumber % 2 > 0 ? -((pinNumber - 1) / 2 * gapX) + (pin * gapX) : -(pinNumber / 2 * gapX) + gapX / 2 + (pin * gapX)
      const yPos = row * gapY
      pinsPositions[row].push({ x: xPos, y: yPos })
    }
  }

  return pinsPositions
}

export const countTotalPins = () => {
  let count = 0
  for (let arrayElement = 0; arrayElement < pinsTransform().length; arrayElement++) {
    count += pinsTransform()[arrayElement].length
  }
  return count
}
