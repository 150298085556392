export const getAllCurrencyUrl = '/system/get-all-currency'
export const getGameSettingsUrl = '/system/get-game-settings'
export const getUserDetailsUrl = '/user/user-detail'
export const loginUrl = '/authenticate/login'
export const getUserListUrl = '/user/get-user-list'
export const getMyBetsUrl = '/crash-game/my-bets'
export const getCrashGameStatusUrl = '/crash-game/get-crash-game-status'
export const getCrashGameHistoryUrl = '/crash-game/get-crash-game-history'
export const getMultiplierUrl = '/crash-game/get-multiplier'
export const getHugeWinnersUrl = '/crash-game/get-huge-winners'
export const getTopWinnersUrl = '/crash-game/get-top-winners'
export const getMessagesUrl = '/chat/get-messages'
export const updateUnseenCountUrl = '/chat/update-unseen-count'
export const crashGamePlaceBetUrl = '/crash-game/place-bet-crash-game'
export const crashGameCancelBetUrl = '/crash-game/cancel-bet-crash-game'
export const crashGameEscapeBetUrl = '/crash-game/player-escape-crashGame'
export const plinkoPlaceBetUrl = 'plinko-game/place-bet'
export const plinkoMyBetsUrl = 'plinko-game/my-bets'
export const plinkoLighteningBoardUrl = 'plinko-game/lightning-board-details'
export const genarateServerSeedUrl = 'user/generate-server-seed'
export const hiloPlaceBet = 'hi-lo-game/place-bet'
export const hiloOpenCard = 'hi-lo-game/open-card'
export const getHiloBets = 'hi-lo-game/my-bets'
export const hiloCashOutBet = 'hi-lo-game/cash-out-bet'
export const currentBetStateUrl = 'hi-lo-game/current-bet-state'
export const kenoPlaceBetUrl = 'keno-game/place-bet'
export const kenoMyBetsUrl = 'keno-game/my-bets'
