export const isPixiStandAlone = false

const basePath = isPixiStandAlone ? '/public/assets/pixi/plinko-game/' : '/pixiAssets/plinko-game/'

export const path = {
  imagePath: basePath + 'images/',
  audioPath: basePath + 'audios/',
  spriteSheetsPath: basePath + 'spriteSheets/'
}

export const settings = {
  originalWidth: 1080,
  originalHeight: 1080,

  pinInFirstLine: 3,
  // totalLines: lines,
  maxLines: 16,

  bgAssets: {
    speed: 0.06
  },

  bounce: {
    initialHeight: 40,
    speed: 0.22,
    offset: {
      x: 10,
      y: 10
    }
  },

  movingBall: {
    speed: 0.3
  },

  gaps: {
    x: 54, // 54
    y: 44 // 44
  },

  styleWhite: {
    fontFamily: 'PlinkoFont',
    fontSize: 23,
    fontStyle: '',
    fontWeight: '900',
    fill: ['#FFFFFF'],
    strokeThickness: 0,
    wordWrap: true,
    wordWrapWidth: 440
  }

}
