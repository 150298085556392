import { getData, postData } from '../../reactQuery/axios/apiCalls'
import { currentBetStateUrl, getHiloBets, hiloCashOutBet, hiloOpenCard, hiloPlaceBet } from '../../reactQuery/axios/urls'

export const placeBetHiLoService = (data) => {
  return postData({ url: hiloPlaceBet, body: data })
}

export const openCardHiLoService = (data) => {
  return postData({ url: hiloOpenCard, body: data })
}

export const myBetsHiLoService = (data) => {
  return getData({ url: getHiloBets, params: data })
}

export const cashOutBetHiLoService = (data) => {
  return postData({ url: hiloCashOutBet, body: data })
}

export const myCurrentBetStateService = () => {
  return getData({ url: currentBetStateUrl, params: {} })
}
