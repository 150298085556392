import { createSlice } from '@reduxjs/toolkit'
import { plus } from 'number-precision'

const initialState = {
  selectedWallet: null,
  userBalance: 0,
  redirectToAfterLogin: '',
  userGameToggleSettings: { music: false, sound: false, animation: true },
  token: '',
  isTokenExpired: false,
  creditWalletWaitQueue: [],
  isLoggedIn: false,
  userName: ''
}

const {
  actions: {
    setUserBalance, setUserGameToggleSettings, setCurrencyDetail, setToken,
    setIsTokenExpired, updateCreditWalletWaitQueue, setUserInfo, appendCreditWalletWaitQueue,
    setUserName, setCreditQueue, reflectCreditQueueAmount
  },
  reducer
} = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserBalance: (state, action) => ({
      ...state,
      userBalance: action.payload?.amount,
      isLoggedIn: true,
      selectedWallet: { ...state.selectedWallet, ...action.payload }
    }),
    setUserGameToggleSettings: (state, action) => ({
      ...state,
      userGameToggleSettings: action.payload
    }),
    setCurrencyDetail: (state, action) => ({
      ...state,
      currencyDetail: action.payload.currency
    }),
    setToken: (state, action) => ({
      ...state,
      token: action.payload
    }),
    setIsTokenExpired: (state, action) => ({
      ...state,
      isTokenExpired: action.payload
    }),
    appendCreditWalletWaitQueue: (state, action) => {
      return {
        ...state,
        creditWalletWaitQueue: [...state.creditWalletWaitQueue, action.payload]
      }
    },
    updateCreditWalletWaitQueue: (state) => {
      let _creditWalletWaitQueue = state.creditWalletWaitQueue
      const _userInfoWallet = _creditWalletWaitQueue[_creditWalletWaitQueue.length - 1] ?? state.selectedWallet
      _creditWalletWaitQueue = _creditWalletWaitQueue?.slice(0, _creditWalletWaitQueue.length - 1)
      return {
        ...state,
        creditWalletWaitQueue: _creditWalletWaitQueue,
        selectedWallet: _userInfoWallet
      }
    },
    setCreditQueue: (state, action) => ({
      ...state,
      creditWalletWaitQueue: [...state.creditWalletWaitQueue, action.payload]
    }),
    reflectCreditQueueAmount: (state) => {
      if (state.creditWalletWaitQueue?.length > 0) {
        return {
          ...state,
          selectedWallet: {
            ...state.creditWalletWaitQueue[0],
            amount: plus(+state.selectedWallet.amount, +state.creditWalletWaitQueue[0]?.betInfo?.winningAmount)
          },
          creditWalletWaitQueue: [...state.creditWalletWaitQueue].slice(1)
        }
      }
    },
    setUserInfo: (state, action) => {
      return {
        ...state,
        selectedWallet: action.payload
      }
    },
    setUserName: (state, action) => {
      return {
        ...state,
        userName: action.payload
      }
    }
  }
})

export default reducer
export {
  setUserBalance, setUserGameToggleSettings, setCurrencyDetail, setToken, setIsTokenExpired,
  updateCreditWalletWaitQueue, setUserInfo, appendCreditWalletWaitQueue, setUserName, setCreditQueue, reflectCreditQueueAmount
}
