import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setUserGameToggleSettings } from '../../redux-store/redux/user'
import { playBGMSound } from '../../GameLogic/plinko-game/soundManager'

const PlinkoGameHeader = () => {
  const { userGameToggleSettings, userName } = useSelector(state => state.user)
  const dispatch = useDispatch()

  useEffect(() => {
    playBGMSound()
  }, [userGameToggleSettings?.music])

  const menuList = [
    {
      label: 'Bet History',
      image: 'icon-bet-history.svg',
      value: 'betHistory',
      target: '#betHistoryModal'
    },
    {
      label: 'Game Limits',
      image: 'icon-game-limits.svg',
      value: 'gameLimits',
      target: '#gameLimitsModal'
    },
    {
      label: 'Change Seeds',
      image: 'icon-game-limits.svg',
      value: 'changeSeed',
      target: '#changeSeedModal'
    }
  ]

  return (
    <div className='d-flex bg-white'>
      <div className='header-logo-section text-white'>
        <img src='/images/gice-game-logo.png' alt='logo' className='mr-4 mr-sm-5' />
      </div>
      <div className='header-dropdown-section bg-white w-100 d-flex justify-content-end px-md-3'>
        <div className='btn-group align-self-center'>
          {window.location.href.includes('plinko') && (!(userGameToggleSettings?.music && userGameToggleSettings?.sound)
            ? <img
                className='mx-2 cursor-pointer sound-img' src='/assets/images/stock-images/no-audio.png' alt='audio'
                onClick={() => dispatch(setUserGameToggleSettings({ music: true, sound: true, animation: true }))}
              />
            : <img
                className='mx-2 cursor-pointer sound-img' src='/assets/images/stock-images/audio.png' alt='audio'
                onClick={() => dispatch(setUserGameToggleSettings({ music: false, sound: false, animation: true }))}
              />)}
          <button type='button' className='btn btn-secondary dropdown-toggle mr-2' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'>
            {userName || 'Player'}
          </button>
          <div className='dropdown-menu dropdown-menu-right'>
            {menuList.map(menu => (
              (window.location.href.includes('plinko') ? menu.value !== 'changeSeed' : true) && (
                <button
                  className='dropdown-item'
                  data-toggle='modal'
                  data-target={menu.target}
                  type='button'
                  key={menu.label}
                >{menu.label}
                </button>
              )
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default PlinkoGameHeader
