import { CARD_DECK, DEFAULT_PRECISION_MONEY, HI_LO_GAME_BET_TYPE } from '../constants'

/**
 * Parses payload object from jwt
 * @param {string} token
 * @returns {Object}
 */
export const getPayloadFromToken = (token) => {
  if (token) {
    return JSON.parse(atob(token.split('.')[1]))
  }
}

/**
 * Generate a random card number between [1,52]
 * @returns {number}
 */
export const randomCardGenerate = () => {
  return Math.floor(Math.random() * 52) + 1
}

/**
 *
 * @param {Number} n
 * @returns {Number} factorial of n
 */
export const factorial = n => {
  let fact = 1
  for (let i = 2; i <= n; i++) {
    fact *= i
  }
  return fact
}

/**
 *
 * @param {Number} n
 * @param {Number} r
 * @returns {Number} combination of n over r
 */
export const combination = (n, r) => {
  if (r > n) {
    return 0
  }
  return factorial(n) / (factorial(r) * factorial(n - r))
}

/**
 *
 * @param {Number} numberOfCoins
 * @param {Number} minimumChosenOutcome
 * @returns {Number} probability of minimum X number of favorable outcomes
 */
export const getCoinOutcomeProbability = (numberOfCoins, minimumChosenOutcome) => {
  const n = numberOfCoins - minimumChosenOutcome
  let sum = 0
  for (let i = 0; i <= n; i++) {
    sum += combination(numberOfCoins, i)
  }
  return sum / 2 ** numberOfCoins
}

/**
 * To calculate flip coin potential win
 * @param {number} betAmount
 * @param {number} numberOfCoins
 * @param {number} minimumChosenOutcome
 * @param {number} houseEdge
 * @returns {number}
 */
export const getFlipCoinPotentialWin = (betAmount, numberOfCoins, minimumChosenOutcome, houseEdge) => {
  const probability = getCoinOutcomeProbability(numberOfCoins, minimumChosenOutcome)
  const odds = (1 / probability) * (1 - houseEdge / 100)
  return +Number((betAmount * odds)).toFixed(2)
}

/**
 *
 * @param {string} s
 * @returns {Number} count of one
 */
export const countOnes = (s) => {
  let count = 0
  for (const i of s) {
    if (i === '1') {
      count++
    }
  }
  return count
}

/**
 *
 * @param  {...Array} a Specify arrays to do cartesian product
 * @returns {Array.<Array.<number[]>>} Returns the cartesian product of given arrays
 */
export const cartesianProductOfArrays = (...a) => a.reduce((a, b) => a.flatMap(d => b.map(e => [d, e].flat())))

export const generateClientSeed = () => {
  const availableChars = '0123456789abcdef'
  let seed = ''
  for (let i = 0; i < 32; i++) {
    seed +=
          availableChars[Math.floor(Math.random() * availableChars.length)]
  }
  return seed
}

/**
 *
 * @param {number} value
 * @param {number} precision
 * @returns {number | string} Returns precision value for specified decimal places
 */
export const getPrecision = (value, precision = DEFAULT_PRECISION_MONEY) => {
  const precisionDivide = 10 ** precision
  const result = parseInt(value * precisionDivide) / precisionDivide
  return result || ''
}

/**
 *
 * @param {Object} gameSettings
 * @param {Number} probability
 * @returns {Number} Calculated Odds
 */
export const calculateOdds = (gameSettings, odds) => {
  return Math.max(gameSettings.minOdd, Math.min(gameSettings.maxOdd, odds * (1 - gameSettings.houseEdge / 100)))
}

/**
 *
 * @param {Object/Array} betStates
 * @param {Number} initialCard
 * @returns {Number} Calculated Odds
 */
export const getOdds = (betStates, initialCard) => {
  const same = 4 / 52
  const aboveOrBelow = (52 - 4) / 52

  const odds = betStates.reduce((odds, betState, index, betStates) => {
    let chance
    const previousCardNumber = index === betStates?.length - 1 ? initialCard : betStates[index + 1].openedCard
    const [previousCardRank] = CARD_DECK[previousCardNumber]

    if (betState.betType === HI_LO_GAME_BET_TYPE.SAME) {
      chance = same
    } else if (betState.betType === HI_LO_GAME_BET_TYPE.ABOVE) {
      chance = aboveOrBelow
    } else if (betState.betType === HI_LO_GAME_BET_TYPE.BELOW) {
      chance = aboveOrBelow
    } else if (betState.betType === HI_LO_GAME_BET_TYPE.SAME_OR_ABOVE) {
      chance = 4 * ((13 - previousCardRank) + 1) / 52
    } else if (betState.betType === HI_LO_GAME_BET_TYPE.SAME_OR_BELOW) {
      chance = previousCardRank * 4 / 52
    }

    odds *= (1 / chance)
    return odds
  }, 1.00)

  return odds || 1.00
}

/**
 *
 * @param {Number} num
 * @param {Number} upto
 * @returns {Number} digits num
 */
export const truncateFloatNum = (num, upto) => {
  return num.toString().slice(0, (num.toString().indexOf('.')) + upto + 1)
}

/**
 *
 * @param {function} func
 * @returns {Number} result
 */
export const memoize = (func) => {
  const cache = new Map()

  return function (...args) {
    const key = JSON.stringify(args)

    if (cache.has(key)) {
      return cache.get(key)
    }

    const result = func(...args)
    cache.set(key, result)

    return result
  }
}

/**
 *
 * @param {Array} array
 * @returns {Array} array
 */
export const shuffleArray = (array) => {
  let i = array.length
  let j = 0
  let temp

  while (i--) {
    j = Math.floor(Math.random() * (i + 1))

    // swap randomly chosen element with current element
    temp = array[i]
    array[i] = array[j]
    array[j] = temp
  }

  return array
}
