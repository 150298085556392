import { createAsyncThunk } from '@reduxjs/toolkit'
import { cashOutBetHiLoService, myBetsHiLoService, myCurrentBetStateService, openCardHiLoService, placeBetHiLoService } from '../../network/services/hiloGame.service'
import { currentBetStateUrl, getHiloBets, hiloCashOutBet, hiloOpenCard, hiloPlaceBet } from '../../reactQuery/axios/urls'
import { BET_RESULT } from '../../utils/constants'
import { setServerSeedHash } from '../redux/gameSetting'
import store from '../store'
import { setStateLoaded } from '../redux/hiLoGame'

export const placeBetHiLo = createAsyncThunk(hiloPlaceBet, async ({ payload, addToast }, thunkApi) => {
  try {
    // const { onSuccess } = payload
    const res = await placeBetHiLoService(payload)
    // onSuccess()
    addToast('Bet Placed Successfully', { appearance: 'success' })
    return res?.data?.data
  } catch (error) {
    addToast('Place Bet Failed', { appearance: 'error' })
    return thunkApi.rejectWithValue(error[0].description)
  }
})

export const openCardHiLo = createAsyncThunk(hiloOpenCard, async ({ data, addToast, setBetAmount, minBetData, setPreviousSelectedMultiplier, setShowWinPopup }, thunkApi) => {
  try {
    const res = await openCardHiLoService(data)
    if (res?.data?.data?.result === BET_RESULT.LOST) {
      setPreviousSelectedMultiplier(1)
      setShowWinPopup(true)
      addToast('Player Lost', { appearance: 'error' })
      store.dispatch(setServerSeedHash(res?.data?.data?.nextServerSeedHash))
      setBetAmount(Number(minBetData))
    }
    return res?.data?.data
  } catch (error) {
    return thunkApi.rejectWithValue(error[0].description)
  }
})

export const getMyBetsHiLo = createAsyncThunk(getHiloBets, async (data, thunkApi) => {
  try {
    const res = await myBetsHiLoService(data)
    return res?.data?.data
  } catch (error) {
    return thunkApi.rejectWithValue(error[0].description)
  }
})

export const cashOutHiLo = createAsyncThunk(hiloCashOutBet, async ({ data, addToast, setShowWinPopup }, thunkApi) => {
  try {
    const res = await cashOutBetHiLoService(data)
    setShowWinPopup(true)
    addToast('Cash Out Successfully', { appearance: 'success' })
    store.dispatch(setServerSeedHash(res?.data?.data?.nextServerSeedHash))
    return res?.data?.data
  } catch (error) {
    addToast('Cash Out Failed', { appearance: 'error' })
    return thunkApi.rejectWithValue(error[0].description)
  }
})

export const currentBetState = createAsyncThunk(currentBetStateUrl, async ({ addToast }, thunkApi) => {
  try {
    const res = await myCurrentBetStateService()
    store.dispatch(setStateLoaded(true))
    if (res?.data?.data?.data?.length) {
      addToast('You already have a Bet Placed.', { appearance: 'success' })
      return res?.data?.data?.data?.[0]
    } else {
      return thunkApi.rejectWithValue('Error')
    }
  } catch (error) {
    store.dispatch(setStateLoaded(true))
    return thunkApi.rejectWithValue(error[0].description)
  }
})
